<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.einstufung.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.einstufung.list.title"></app-i18n>
      </h1>

      <app-einstufung-list-toolbar></app-einstufung-list-toolbar>
      <app-einstufung-list-filter></app-einstufung-list-filter>
      <app-einstufung-list-table></app-einstufung-list-table>
    </div>
  </div>
</template>

<script>
import EinstufungListFilter from '@/modules/einstufung/components/einstufung-list-filter.vue';
import EinstufungListTable from '@/modules/einstufung/components/einstufung-list-table.vue';
import EinstufungListToolbar from '@/modules/einstufung/components/einstufung-list-toolbar.vue';

export default {
  name: 'app-einstufung-list-page',

  components: {
    [EinstufungListFilter.name]: EinstufungListFilter,
    [EinstufungListTable.name]: EinstufungListTable,
    [EinstufungListToolbar.name]: EinstufungListToolbar,
  },
};
</script>

<style></style>
