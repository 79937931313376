<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
      size="small"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        type="selection"
        width="55"
      ></el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.name.label"
        :prop="fields.name.name"
        sortable="custom"
        width="130"
      >
        <template v-slot="scope">{{ presenter(scope.row, 'name') }}</template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.grundgehaltBrutto.label"
        :prop="fields.grundgehaltBrutto.name"
        sortable="custom"
        width="200"
      >
        <template v-slot="scope">
          {{ presenter(scope.row, 'grundgehaltBrutto') }}
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :fixed="isMobile ? undefined : 'right'"
        align="right"
      >
        <template v-slot="scope">
          <div class="table-actions">
            <router-link
              :to="`/einstufung/${scope.row.id}/edit`"
              v-if="hasPermissionToEdit"
            >
              <el-button type="text">
                <app-i18n code="common.edit"></app-i18n>
              </el-button>
            </router-link>

            <el-button
              :disabled="destroyLoading"
              @click="doDestroyWithConfirm(scope.row.id)"
              type="text"
              v-if="hasPermissionToDestroy"
            >
              <app-i18n code="common.destroy"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
    <!--  <el-dialog
      title="nummer"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <app-einstufung-dialog
        :modal="true"
        :record="record"
        @cancel="doCancel"
        @submitRevise="onModalSuccess"
      />
    </el-dialog> -->
  </div>
</template>

<script>
import { EinstufungModel } from '@/modules/einstufung/einstufung-model';
import { mapGetters, mapActions } from 'vuex';
import { EinstufungPermissions } from '@/modules/einstufung/einstufung-permissions';
import { i18n } from '@/i18n';
/* import EinstufungnummerDialog from '@/modules/einstufung/components/einstufung-nummer-dialog'; */

const { fields } = EinstufungModel;

export default {
  name: 'app-einstufung-list-table',

  components: {
    /* [EinstufungnummerDialog.name]: EinstufungnummerDialog, */
  },

  data() {
    return {
      dialogVisible: false,
      record: null,
    };
  },

  mounted() {
    this.$watch(
      () => {
        return this.$refs.table.selection;
      },
      (rows) => {
        this.$store.dispatch('einstufung/list/doSetSelectedRows', rows);
      },
    );
  },

  computed: {
    ...mapGetters({
      rows: 'einstufung/list/rows',
      count: 'einstufung/list/count',
      loading: 'einstufung/list/loading',
      pagination: 'einstufung/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'einstufung/destroy/loading',
      paginationLayout: 'layout/paginationLayout',
    }),

    hasPermissionToEdit() {
      return new EinstufungPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new EinstufungPermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'einstufung/list/doChangeSort',
      doChangePaginationCurrentPage:
        'einstufung/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'einstufung/list/doChangePaginationPageSize',
      doMountTable: 'einstufung/list/doMountTable',
      doDestroy: 'einstufung/destroy/doDestroy',
    }),

    presenter(row, fieldName) {
      return EinstufungModel.presenter(row, fieldName);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },

    doOpenModal(payload) {
      this.record = payload;
      this.dialogVisible = true;
    },

    async onModalSuccess(payload) {
      let { id, values } = payload;
      this.dialogVisible = false;
      return this.doUpdateStatus({ id, values });
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
